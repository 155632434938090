'use client';

/* eslint-disable @typescript-eslint/naming-convention */

import { FormEvent, ReactNode, useEffect, useState } from 'react';

import { useAtom, useSetAtom } from 'jotai';
import { useRouter } from 'next/navigation';

import { toastManagerAtom } from '@core/Components/Toast/ToastManager';

import { AccModalId, activeAccModalAtom } from '../../../Atoms/AccModal.atom';

import { useAuthSubmit } from './UseAuthSubmit';

interface UseAuthSubmitProps {
  url: string;
  method?: 'GET' | 'POST' | 'PATCH';
  authToken?: string;
  sendAsFormData?: boolean;
  onSuccess?: (response: any) => void;
  onError?: (error: any) => void;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => false | void;
  successModalId?: AccModalId | null;
  successUrl?: string;
}

export const useAccountForm = ({
  url,
  method = 'POST',
  authToken,
  sendAsFormData,
  onSuccess,
  onError,
  onSubmit,
  successModalId,
  successUrl,
}: UseAuthSubmitProps) => {
  const { push } = useRouter();
  const addToast = useSetAtom(toastManagerAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [activeAccModal, setActiveAccModal] = useAtom(activeAccModalAtom);
  const [generalFormError, setGeneralFormError] = useState<string | null | ReactNode>(null);

  const { formRef, handleSubmit } = useAuthSubmit({
    url,
    sendAsFormData: sendAsFormData,
    method,
    authToken,

    onSubmit: e => {
      setIsLoading(true);
      setGeneralFormError(null);
      return onSubmit?.(e);
    },

    onSuccess: response => {
      onSuccess?.(response);
      if (successModalId !== undefined) setActiveAccModal(successModalId);
      if (successUrl) push(successUrl);
      setIsLoading(false);
    },

    onError: error => {
      onError?.(error);
      setGeneralFormError(error.statusText);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    setGeneralFormError(null);
  }, [activeAccModal]);

  useEffect(() => {
    if (!formRef.current) return;

    const handleChange = () => {
      setGeneralFormError(null);
    };

    formRef.current.addEventListener('change', handleChange);

    return () => {
      formRef.current?.removeEventListener('change', handleChange);
    };
  }, [formRef?.current]);

  return { formRef, handleSubmit, generalFormError, setGeneralFormError, isLoading };
};
